var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-menu-form',{attrs:{"model":"taskpropertyandagreement","headers":_vm.taskPropertyAndAgreementHeader,"hide-edit":_vm.mode !== 'edit',"hide-delete":_vm.mode !== 'edit',"server-items":_vm.taskPropertyAndAgreementItems,"client-items":_vm.taskPropertyAndAgreementItems,"loading":_vm.loading,"server-items-length":_vm.taskPropertyAndAgreementItemsLength,"events":_vm.eventHandlers},scopedSlots:_vm._u([{key:"item.group_link",fn:function({ item }){return [(item.property_and_agreement)?[_c('router-link',{attrs:{"to":{
          name:
            item.property_and_agreement === 'liability'
              ? 'liabilityEdit'
              : item.property_and_agreement === 'asset'
              ? 'assetEdit'
              : item.property_and_agreement === 'expense'
              ? 'expenseEdit'
              : item.property_and_agreement === 'revenue'
              ? 'revenueEdit'
              : item.property_and_agreement === 'human_asset'
              ? 'humanAssetEdit'
              : item.property_and_agreement === 'lawsuit'
              ? 'lawsuitEdit'
              : 'equityEdit',
          params: { id: item.group_id }
        },"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-link")])],1)]:_vm._e()]}},{key:"top",fn:function(){return [_c('app-menu-form-top',{attrs:{"mode":_vm.mode,"title":_vm.$t('menu.propertyAndAgreement'),"dialog":_vm.dialogTaskPropertyAndAgreement,"selected-length":_vm.taskPropertyAndAgreementSelected.length,"onDelete":_vm.onDeleteTaskPropertyAndAgreement},on:{"show-dialog":function($event){_vm.dialogTaskPropertyAndAgreement = $event}},scopedSlots:_vm._u([{key:"dialogForm",fn:function(){return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"porpery_and_agreement","type":"select","label":_vm.$t('fields.propertyAndAgreement'),"view":_vm.view,"items":[
                      { label: _vm.$t('label.asset'), value: 'asset' },
                      {
                        label: _vm.$t('label.liability'),
                        value: 'liability'
                      },
                      { label: _vm.$t('label.equity'), value: 'equity' },
                      {
                        label: _vm.$t('label.revenue'),
                        value: 'revenue'
                      },
                      {
                        label: _vm.$t('label.expense'),
                        value: 'expense'
                      },
                      {
                        label: _vm.$t('label.human_asset'),
                        value: 'human_asset'
                      },
                      {
                        label: _vm.$t('label.lawsuit'),
                        value: 'lawsuit'
                      }
                    ]},on:{"input":_vm.getGroupName},model:{value:(
                      _vm.editedPropertyAndAgreementItem.property_and_agreement
                    ),callback:function ($$v) {_vm.$set(_vm.editedPropertyAndAgreementItem, "property_and_agreement", $$v)},expression:"\n                      editedPropertyAndAgreementItem.property_and_agreement\n                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"group_name","type":"select-server","label":_vm.$t('fields.groupName'),"view":_vm.view,"binds":{ apiUrl: _vm.groupNameUrl },"disabled":!Boolean(
                        _vm.editedPropertyAndAgreementItem.property_and_agreement
                      )},model:{value:(_vm.editedPropertyAndAgreementItem.group_name),callback:function ($$v) {_vm.$set(_vm.editedPropertyAndAgreementItem, "group_name", $$v)},expression:"editedPropertyAndAgreementItem.group_name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"contact_branch_id","type":"select-server","label":_vm.$t('fields.branchName'),"view":_vm.view,"binds":{
                      apiUrl: `contact/contact-branch/?contact_id=${_vm.contactId.id}`
                    }},model:{value:(_vm.editedPropertyAndAgreementItem.contact_branch_id),callback:function ($$v) {_vm.$set(_vm.editedPropertyAndAgreementItem, "contact_branch_id", $$v)},expression:"editedPropertyAndAgreementItem.contact_branch_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"business_unit_id","type":"select-server","label":_vm.$t('fields.businessUnit'),"view":_vm.view,"binds":{
                      apiUrl: `contact/business-unit/?active=true&contact_id=${_vm.contactId.id}`
                    }},model:{value:(_vm.editedPropertyAndAgreementItem.business_unit_id),callback:function ($$v) {_vm.$set(_vm.editedPropertyAndAgreementItem, "business_unit_id", $$v)},expression:"editedPropertyAndAgreementItem.business_unit_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"note","type":"text","label":_vm.$t('fields.note'),"view":_vm.view},model:{value:(_vm.editedPropertyAndAgreementItem.note),callback:function ($$v) {_vm.$set(_vm.editedPropertyAndAgreementItem, "note", $$v)},expression:"editedPropertyAndAgreementItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeTaskPropertyAndAgreement}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveTaskPropertyAndAgreement}},[_vm._v("Save")])],1)],1)]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }