<template>
  <app-menu-form
    :headers="objectiveHeaders"
    :server-items="objectiveItems"
    :server-items-length="objectiveItemsCount"
    :loading="loading"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :events="eventHandlers"
  >
    <template v-slot:[`item.chart_of_account_details`]="{ value }">
      <div>{{ displayChartOfAccountDetails(value) }}</div>
    </template>

    <template v-slot:top>
      <app-menu-form-top
        title="Objective"
        :mode="mode"
        :dialog="dialogObjective"
        @show-dialog="dialogObjective = $event"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="app-input--approve-wrapper">
                    <app-input
                      v-model="editingObjective.objective"
                      name="objective_objective"
                      type="select-server"
                      :label="$t('fields.objective')"
                      :view="view"
                      :binds="{
                        apiUrl:
                          'objective/objective/?state=approved&active=true'
                      }"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="app-input--approve-wrapper">
                    <app-input
                      v-model="editingObjective.chart_of_account_details"
                      name="objective_chart_of_account_details"
                      type="chips-server"
                      :label="$t('fields.chartOfAccountName')"
                      :view="view"
                      :binds="{
                        apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`
                      }"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseObjective">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveObjective">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import { defaultTableParams } from '@utils/app-table'
import AppInput from '@components/AppInput'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'

export default {
  name: 'app-task-objective',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: String,
    view: Boolean
  },
  data() {
    return {
      dialogObjective: false,
      loading: false,
      objectiveItems: [],
      objectiveItemsCount: 0,
      editingObjective: {}
    }
  },
  computed: {
    objectiveHeaders() {
      return [
        {
          text: this.$t('fields.objective'),
          value: 'objective.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.chartOfAccountName'),
          value: 'chart_of_account_details',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getObjectives,
        edit: this.onEditObjective,
        delete: this.onDeleteObjective
      }
    }
  },
  methods: {
    async getObjectives(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/objective/`,
          params: params,
          hideSuccessAlert: true
        })
        this.objectiveItems = data.results
        this.objectiveItemsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditObjective(item) {
      this.editingObjective = Object.assign({}, item)
      this.dialogObjective = true
    },
    onDeleteObjective(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/objective/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getObjectives()
        })
      })
      this.loading = false
    },
    displayChartOfAccountDetails(value) {
      return value.map((v) => v.name).join(', ')
    },
    onCloseObjective() {
      this.dialogObjective = false
      this.$nextTick(() => {
        this.editingObjective = {}
      })
      this.loading = false
    },
    async onSaveObjective() {
      if (
        !this.editingObjective.objective ||
        !this.editingObjective.chart_of_account_details
      ) {
        return
      }
      try {
        this.loading = true
        const data = {
          objective_id: this.editingObjective.objective.id,
          chart_of_account_detail_ids:
            this.editingObjective.chart_of_account_details.map(
              (value) => value.id
            )
        }

        if (this.editingObjective.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/objective/${this.editingObjective.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/objective/`,
            data
          })
        }
        this.onCloseObjective()
        await this.getObjectives()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
