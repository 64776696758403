<template>
  <app-menu-form
    model="taskquestionnaire"
    :headers="taskQuestionnaireHeader"
    :server-items="taskQuestionnaireItems"
    :client-items="taskQuestionnaireItems"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :loading="loading"
    :server-items-length="taskQuestionnaireItemsLength"
    :events="eventsHanlder"
  >
    <template v-slot:top>
      <div class="app-list-view">
        <div class="app-list-view--header">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('menu.questionnaire') }} </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialogQuestionAnswer" max-width="1500px">
              <v-card>
                <v-card-text class="pt-4">
                  <v-toolbar-title>Answer</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-row v-if="taskQuestionnaireType === 'Text'">
                    <v-col cols="12" sm="6" md="4">
                      <app-input
                        v-model="editedItem.text_answer"
                        name="answer"
                        :label="$t('fields.answer')"
                        :view="false"
                      ></app-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="taskQuestionnaireType === 'MultipleChoice'">
                    <v-col cols="12" sm="6" md="4">
                      <v-radio-group v-model="editedItem.questionAnswer">
                        <v-radio
                          v-for="item in answerItem"
                          :key="item.id"
                          :label="item.choice"
                          :value="{
                            id: item.id,
                            points: item.points
                          }"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="taskQuestionnaireType === 'Checkbox'">
                    <v-col cols="12" sm="6" md="4">
                      <p>Multiple Select</p>
                      <div v-for="item in answerItem" :key="item.id">
                        <v-checkbox
                          v-model="selectedItem"
                          :label="item.choice"
                          :value="{
                            id: item.id,
                            points: item.points
                          }"
                          :key="item.id"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    ><v-col cols="12" sm="6" md="4">
                      <app-input
                        v-model="editedItem.answerScore"
                        name="answerScore"
                        :label="$t('fields.score')"
                        type="number"
                        :view="false"
                      ></app-input> </v-col
                  ></v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeQuestionAnswer"
                  >
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveQuestionAnswer">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogTaskQuestionnaire" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <slot name="actions">
                  <slot name="prefix-actions" />
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    v-show="mode === 'edit'"
                    @click="onQuestionnaireNewItemClick"
                  >
                    New Item
                  </v-btn>
                  <v-btn
                    v-show="taskQuestionnaireSelected.length && mode === 'edit'"
                    color="error"
                    @click="
                      onDeleteTaskQuestionnaire(taskQuestionnaireSelected)
                    "
                  >
                    {{ $t('btn.delete') }}
                  </v-btn>

                  <slot name="suffix-actions" />
                </slot>
              </template>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row v-if="isNewQuestionnaireClicked">
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          name="Select Quesiton"
                          type="select"
                          class="w-100"
                          :label="$t('fields.selectQuestion')"
                          :items="[
                            {
                              label: 'Pre Config Questionnaire',
                              value: 'PreConfig'
                            },
                            {
                              label: 'Add Manual Question',
                              value: 'Manual'
                            }
                          ]"
                          v-model="editedItem.selectQuestion"
                          :view="view"
                        ></app-input>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.selectQuestion === 'PreConfig'">
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          name="Questionnaire"
                          type="select-server"
                          class="w-200"
                          :label="$t('fields.questionnaire')"
                          v-model="editedItem.questionnaire_header_id"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'questionnaire/questionnaire-header?active=true'
                          }"
                        ></app-input>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        editedItem.selectQuestion === 'Manual' ||
                        !isNewQuestionnaireClicked
                      "
                    >
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          v-model="editedItem.sequence"
                          name="sequence"
                          :label="$t('fields.sequence')"
                          type="number"
                          :view="view"
                        ></app-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          v-model="editedItem.question"
                          name="question"
                          :label="$t('fields.question')"
                          :view="view"
                        ></app-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          name="Question Type"
                          type="select"
                          class="w-100"
                          :label="$t('fields.questionType')"
                          :items="[
                            {
                              label: 'Text',
                              value: 'Text'
                            },
                            {
                              label: 'MultipleChoice',
                              value: 'MultipleChoice'
                            },
                            {
                              label: 'Checkbox',
                              value: 'Checkbox'
                            }
                          ]"
                          v-model="editedItem.question_type"
                          :view="view"
                        >
                        </app-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" style="text-align: right">
                        <v-btn
                          color="primary"
                          dark
                          v-show="mode === 'edit'"
                          @click="onEditQuestionnaireLine()"
                          v-if="
                            (editedItem.selectQuestion === 'Manual' ||
                              !isNewQuestionnaireClicked) &&
                            (editedItem.question_type === 'MultipleChoice' ||
                              editedItem.question_type === 'Checkbox')
                          "
                        >
                          {{ $t('btn.newItem') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col col="12">
                        <app-table
                          app="questionnaire"
                          model="questionnaireline"
                          :headers="questionnaireLineHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="false"
                          :client-items="editingQuestionnaire.lines"
                          @edit="onEditQuestionnaireLine"
                          @delete="onDeleteQuestionnaireLine"
                          v-if="
                            (editedItem.selectQuestion === 'Manual' ||
                              !isNewQuestionnaireClicked) &&
                            (editedItem.question_type === 'MultipleChoice' ||
                              editedItem.question_type === 'Checkbox')
                          "
                        >
                        </app-table>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        editedItem.selectQuestion === 'Manual' ||
                        !isNewQuestionnaireClicked
                      "
                    >
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          v-model="editedItem.total_points"
                          name="total_points"
                          :label="$t('fields.totalPoints')"
                          type="number"
                          :view="view"
                          :disabled="
                            editedItem.question_type === 'MultipleChoice' ||
                            editedItem.question_type === 'Checkbox'
                          "
                        ></app-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <app-input
                          v-model="editedItem.note"
                          name="note"
                          :label="$t('fields.note')"
                          :view="view"
                        ></app-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeQuestionnaireDetail"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="saveQuestionnaireDetail"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogQuestionnaireLine" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="app-input--approve-wrapper">
                        <app-input
                          v-model="editingQuestionnaireLine.choice"
                          name="choice"
                          :label="$t('fields.choice')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="app-input--approve-wrapper">
                        <app-input
                          v-model="editingQuestionnaireLine.points"
                          name="points"
                          type="number"
                          :label="$t('fields.points')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseQuestionnaireLine"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSaveQuestionnaireLine"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </div>
      </div>
    </template>
    <template slot="body.append">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-subtitle-1">Totals</td>
        <td class="text-subtitle-1">
          {{ sumPoints }}
        </td>
        <td class="text-subtitle-1">
          {{ sumScore }}
        </td>
        <td></td>
      </tr>
    </template>
  </app-menu-form>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
// import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'

export default {
  name: 'app-questionnaire',
  components: {
    AppTable,
    AppInput,
    AppMenuForm
    // AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogTaskQuestionnaire: false,
      dialogQuestionnaireLine: false,
      taskQuestionnaireType: null,
      isNewQuestionnaireClicked: false,
      taskQuestionnaireItems: [],
      loading: false,
      answerItem: [],
      selectedItem: [],
      editingQuestionnaireLine: {},
      dialogQuestionAnswer: false,
      taskQuestionnaireSelected: [],
      taskQuestionnaireItemsLength: 0,
      taskQuestionnaireAnswer: null,
      editingQuestionnaire: {},
      taskQuestionnaireId: null,
      taskQuestionnaireClientItems: [],
      editedItem: {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      },
      apiMethod: 'post'
    }
  },
  watch: {
    'editedItem.questionAnswer': {
      handler() {
        this.totalScore()
      },
      deep: true // This ensures the watcher triggers even if the objects inside the array are mutated
    },
    selectedItem: {
      handler() {
        this.totalScore()
      },
      deep: true // This ensures the watcher triggers even if the objects inside the array are mutated
    }
  },
  computed: {
    sumPoints() {
      return this.sumPointsItem('total_points')
    },
    sumScore() {
      return this.sumScoreItem('score')
    },
    taskQuestionnaireHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.question'),
          value: 'question',
          hideFilter: true
        },
        {
          text: this.$t('fields.questionType'),
          value: 'question_type',
          hideFilter: true
        },
        {
          text: this.$t('fields.points'),
          value: 'total_points',
          hideFilter: true
        },
        {
          text: this.$t('fields.score'),
          value: 'answers.score',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    questionnaireLineHeaders() {
      return [
        {
          text: this.$t('fields.choice'),
          value: 'choice',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.points'),
          value: 'points',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    computedSelectedItems() {
      if (
        this.taskQuestionnaireType === 'MultipleChoice' ||
        this.taskQuestionnaireType === 'Checkbox'
      ) {
        if (
          !this.taskQuestionnaireAnswer ||
          !this.taskQuestionnaireAnswer[0].selected_task_questionnaire
        ) {
          return []
        }
        return this.taskQuestionnaireAnswer[0].selected_task_questionnaire.map(
          (item) => ({
            id: item.id,
            points: item.points
          })
        )
      }
      return []
    },
    eventsHanlder() {
      return {
        server: this.getTaskQuestionnaireItems,
        client: this.getTaskQuestionnaireClientItems,
        delete: this.onDeleteTaskQuestionnaireItem,
        edit: this.onEditTaskQuestionnaire,
        view: this.onViewQuestionAnswer
      }
    }
  },
  methods: {
    getTaskQuestionnaireItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'sequence'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'sequence'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-questionnaire/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskQuestionnaireItemsLength = data.count
          this.taskQuestionnaireItems = data.results
          this.taskQuestionnaireClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskQuestionnaireClientItems(multiSearch) {
      this.taskQuestionnaireClientItems = this.taskQuestionnaireItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    sumPointsItem(key) {
      // sum data in give key (property)
      return this.taskQuestionnaireItems.reduce(
        (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
        parseFloat(0)
      )
    },
    sumScoreItem() {
      // sum data in give key (property)
      return this.taskQuestionnaireItems.reduce((acc, result) => {
        if (result.answers && result.answers.score) {
          return acc + result.answers.score
        }
        return acc
      }, 0)
    },
    totalScore() {
      // Use reduce to sum up the points in selectedItem
      if (this.taskQuestionnaireType === 'Checkbox') {
        if (this.selectedItem.length > 0) {
          this.editedItem.answerScore = this.selectedItem.reduce(
            (acc, value) => acc + value.points,
            0
          )
        }
      }
      if (this.taskQuestionnaireType === 'MultipleChoice') {
        if (this.editedItem.questionAnswer) {
          this.editedItem.answerScore = this.editedItem.questionAnswer.points
        }
      }
      return this.editedItem.answerScore
    },
    closeQuestionAnswer() {
      this.editedItem = {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      }
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.apiMethod = 'post'
      this.dialogQuestionAnswer = false
    },
    saveQuestionAnswer() {
      this.loading = true

      const data = {
        task_questionnaire_id: this.taskQuestionnaireId,
        text_answer: this.editedItem.text_answer,
        selected_task_questionnaire: this.getQuestionItemKey(),
        score: this.editedItem.answerScore
      }

      this.$api({
        method: this.apiMethod,
        url: `task/task-questionnaire-answer/${
          this.editedItem.id ? this.editedItem.id + '/' : ''
        }`,
        data
      })

      this.editedItem = {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      }
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.dialogQuestionAnswer = false
      this.apiMethod = 'post'
      this.loading = false
    },
    onQuestionnaireNewItemClick(item) {
      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )
      this.isNewQuestionnaireClicked = true
    },
    onDeleteTaskQuestionnaire(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-questionnaire/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.taskQuestionnaireSelected = []
          this.getTaskQuestionnaireItems()
        })
      })
    },
    getQuestionItemKey() {
      if (this.taskQuestionnaireType === 'Text') {
        return []
      }
      if (this.selectedItem.length > 0) {
        return this.selectedItem.map((item) => item.id)
      }

      if (this.editedItem.questionAnswer) {
        if (this.apiMethod === 'put') {
          return [this.editedItem.questionAnswer.id]
        } else {
          return [this.editedItem.questionAnswer.id]
        }
      }
    },
    closeQuestionnaireDetail() {
      this.editedItem = {}
      this.dialogTaskQuestionnaire = false
      this.isNewQuestionnaireClicked = false
    },
    async saveQuestionnaireDetail() {
      this.loading = true

      this.editedItem.audit_plan_header_id = this.$route.params.audit_plan_id
      this.editedItem.task_id = this.$route.params.id
      if (this.editedItem.questionnaire_header_id) {
        await this.$api({
          method: 'get',
          url: 'questionnaire/questionnaire-detail/',
          params: {
            questionnaire_header_id: this.editedItem.questionnaire_header_id.id
          },
          hideSuccessAlert: true
        }).then(({ data }) => {
          data.results.forEach(async (item) => {
            this.editedItem.sequence = item.sequence
            this.editedItem.question = item.question
            this.editedItem.question_type = item.question_type
            this.editedItem.note = item.note
            this.editedItem.task_id = this.$route.params.id
            this.editedItem.lines = item.lines
            this.editedItem.total_points = item.total_points
            this.editedItem.active = true
            const data = this.editedItem
            await this.$api({
              method: this.apiMethod,
              url: `task/task-questionnaire/${
                this.editId ? this.editId + '/' : ''
              }`,
              data
            })
          })

          this.loading = false
        })
      } else {
        this.editedItem.lines = this.editingQuestionnaire.lines.map((line) => ({
          choice: line.choice,
          points: line.points
        }))
        this.editedItem.active = true
        this.editedItem.task_id = this.$route.params.id
        const data = this.editedItem

        await this.$api({
          method: this.apiMethod,
          url: `task/task-questionnaire/${
            this.editId ? this.editId + '/' : ''
          }`,
          data
        })
      }
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeQuestionnaireDetail()
      this.getTaskQuestionnaireItems()
    },
    onEditQuestionnaireLine(line) {
      if (line) {
        this.editingQuestionnaireLine = line
      } else {
        this.editingQuestionnaireLine = Object.assign({
          choice: '',
          points: ''
        })
      }
      this.dialogQuestionnaireLine = true
    },
    onDeleteQuestionnaireLine(items) {
      for (const item of items) {
        const i = this.editingQuestionnaire.lines.findIndex(
          (line) => line === item
        )
        if (i >= 0) {
          this.editingQuestionnaire.lines.splice(i, 1)
        }
      }

      this.updateTotalPoints()
    },
    onCloseQuestionnaireLine() {
      this.dialogQuestionnaireLine = false
      this.$nextTick(() => {
        this.editingQuestionnaireLine = {}
      })
    },
    async onSaveQuestionnaireLine() {
      const i = this.editingQuestionnaire.lines.findIndex(
        (line) => line === this.editingQuestionnaireLine
      )
      if (i >= 0) {
        this.editingQuestionnaire.lines[i] = this.editingQuestionnaireLine
      } else {
        this.editingQuestionnaire.lines.push(this.editingQuestionnaireLine)
      }

      this.updateTotalPoints()
      this.onCloseQuestionnaireLine()
      return
    },
    onDeleteTaskQuestionnaireItem(item) {
      this.onDeleteTaskQuestionnaire(item)
    },
    onEditTaskQuestionnaire(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id

      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )

      this.dialogTaskQuestionnaire = true
    },
    onViewQuestionAnswer(item) {
      this.editedItem.text_answer = ''
      this.editedItem.answerScore = 0
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.taskQuestionnaireId = item.id
      this.taskQuestionnaireType = item.question_type
      if (
        this.taskQuestionnaireId !== null &&
        this.taskQuestionnaireId !== undefined
      ) {
        this.questionLineUrl =
          `task/task-questionnaire-line?task_questionnaire_id_id=` +
          this.taskQuestionnaireId
      }

      this.getCheckboxItem()
      this.dialogQuestionAnswer = true
    },
    async getCheckboxItem() {
      const { data } = await this.$api({
        method: 'get',
        url:
          `task/task-questionnaire-line?task_questionnaire_id_id=` +
          this.taskQuestionnaireId,
        hideSuccessAlert: true
      })
      this.answerItem = data.results

      await this.$api({
        method: 'get',
        url:
          `task/task-questionnaire-answer?task_questionnaire_id_id=` +
          this.taskQuestionnaireId,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.taskQuestionnaireAnswer = data.results
        this.editedItem.text_answer = data.results[0].text_answer
        this.editedItem.answerScore = data.results[0].score
        this.editedItem.id = data.results[0].id
        this.apiMethod = 'put'
      })

      if (this.computedSelectedItems.length > 0) {
        if (this.taskQuestionnaireType === 'Checkbox') {
          this.selectedItem = this.computedSelectedItems
        }
        if (this.taskQuestionnaireType === 'MultipleChoice') {
          this.editedItem.questionAnswer = this.computedSelectedItems[0]
        }
      }
    },
    updateTotalPoints() {
      let totalPoints = 0

      if (this.editedItem.question_type === 'MultipleChoice') {
        // Get the maximum point value
        totalPoints = Math.max(
          ...this.editingQuestionnaire.lines.map((line) => line.points)
        )
      } else if (this.editedItem.question_type === 'Checkbox') {
        // Sum the point values
        totalPoints = this.editingQuestionnaire.lines.reduce((sum, line) => {
          return sum + line.points
        }, 0)
      }

      this.editedItem.total_points = totalPoints
    }
  }
}
</script>
